import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DeepExtractType } from 'ts-deep-extract-types';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { Container, Section } from '../components/generic/View';
import Content from '../components/RichContent';

type Props = DeepExtractType<
  GatsbyTypes.FourNullFourPageTemplateQuery,
  ['markdownRemark', 'frontmatter']
>;

export const FourNullFourPageTemplate: FC<Props> = ({
  content_section,
  footer_section
}) => {
  return (
    <>
      <Section>
        <Container>
          <Content markdown={content_section?.rich_text} />
        </Container>
      </Section>
      <Footer richText={footer_section?.rich_text || ''} />
    </>
  );
};

const FourNullFourPage: FC<{
  data: GatsbyTypes.FourNullFourPageTemplateQuery;
}> = ({ data }) => (
  <Layout
    pageTitle={data.markdownRemark?.frontmatter?.pageTitle || ''}
    metaTagDescription={
      data.markdownRemark?.frontmatter?.meta_tag_description || ''
    }
  >
    <FourNullFourPageTemplate
      pageTitle={data.markdownRemark?.frontmatter?.pageTitle}
      meta_tag_description={
        data.markdownRemark?.frontmatter?.meta_tag_description
      }
      content_section={data.markdownRemark?.frontmatter?.content_section}
      footer_section={data.markdownRemark?.frontmatter?.footer_section}
    />
  </Layout>
);

export default FourNullFourPage;

export const pageQuery = graphql`
  query FourNullFourPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "four-null-four-page" } }
    ) {
      frontmatter {
        pageTitle
        meta_tag_description
        content_section {
          rich_text
        }
        footer_section {
          rich_text
        }
      }
    }
  }
`;
